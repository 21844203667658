import { Injectable, makeStateKey } from "@angular/core";
import { Irequest, Manager, Iresponse } from "@core/managers/Manager";
import { Iuser } from "@core/interfaces/user.interface";
import { environment } from "@environments/environment";
import {COUNTRIES_DB} from '@angular-material-extensions/select-country'; 
import { Rent } from "@core/classes/rent";
import { firstValueFrom } from "rxjs";
import { RequestManager } from "./RequestManager";
import { Utils } from "@utils/Utils";
import { GLOBAL_FORMAT } from "@data/const/format-date";
import moment from "moment";

const USER_INFORMATION = makeStateKey<any>('USER_INFORMATION');
const USER_BOOKING_INFO = makeStateKey<any>('USER_BOOKING_INFO');

@Injectable({
  providedIn: 'root'
})
export class UserManager extends Manager{

    getUser() : Iuser|undefined{
        
        return this.auth.getUser();
    }

    async getBooked() {

      const user = this.getUser();
      if(!user) return {next : null, error : null};
      
      const method : string = `user/bookings/${user?.id}`;
      const request : Irequest = {
          url: method
      }

      return await this._sync_get(request);
    }
    

    getAge(user : any){
      if(!user) return '';
      if(!user.birthdate) return '';

      const momn = Utils.strToMoment(user.birthdate,GLOBAL_FORMAT);
      const hoy = moment();
      return hoy.diff(momn, "years");
    }

    isLandlord() : boolean {
        return this.auth.getUser()?.landlord ? true : false;
    }

    removeProfileImage(){
        this.auth.removeProfileIcon();
    }   

    profileImage(){
        this.auth.setProfileIcon(true);
    }

    getInformation(response : Iresponse) {
        
        const request : Irequest  = {
            url : 'user/information',
            key: USER_INFORMATION
        }

        this._get(request,response?? {})
    }

    notComplete() {
        const user = this.getUser();
        if(!user) return true;
        
        return !user.gender || !user.birthdate || !user.phone || !user.phone_code || !user.nationality
    }

    getProfileImage(update=false) {
        if(!this.auth.hasProFileIcon()) return null;
        const icone =  this.auth.getProfileIcon();
        if(icone?.startsWith('http')) return icone;
        if(update) return environment.url + 'user-image/get?'+ Date.now();
        return environment.url + 'user-image/get';
    }

    deleteProfileImage(response : Iresponse){
        const request : Irequest = {
            url: 'user-image/delete',
            contentType: null
        }
        
    
        this._delete(request,response?? {});
    }

    getBookingInfo(response : Iresponse){
        const request : Irequest =  {
            url : 'user/booking-info',
            key : USER_BOOKING_INFO
        }
        this._get(request,response)
    }

    changeFiles(data : {image?: File|undefined}, response ?: Iresponse){
        if(!data.image){
        return;
        }  
        const method : string = `user-image`;

        const formData = new FormData();
        if(data.image){
            formData.append('image',data.image)
        }
    
        const request : Irequest = {
            url: method,
            body : formData,
            contentType: null
        }
    
        this._post(request,response?? {});
        return true;
    }

    save(body : any, response : Iresponse){


        const request : Irequest = {
            url: 'user/update',
            body : body,
        }
    
        this._patch(request,response?? {});
    }

    saveUserInformation(body : any,body2 : any, response : Iresponse){


        const request : Irequest = {
            url: 'user/update-information',
            body : {
                emergency : body,
                address : body2
            },
        }
    
        this._patch(request,response?? {});

    }

    private searchCountryByName(name : string){
        return COUNTRIES_DB.find((v : any)=> v.name == name) ?? undefined;
      }
    
    getCountryAsset(country : any){
    if(!country){
        return null;
    }

    if(typeof country == 'string'){
        country = this.searchCountryByName(country);
        if(!country) return null;
    }

    return '/assets/svg-country-flags/svg/'+country.alpha2Code?.toLowerCase()+".svg";
    }
    
    getCountryName(country : any){
    if(!country) return null;
    if(typeof country == 'string') return country;

    return country.name;
    }

    getGender(user: Iuser) : string{
        if(typeof user.gender === 'string') return user.gender;

        if(user.gender == 2) return 'female';
        if(user.gender == 1) return 'male';
        if (user.gender == 3) return 'other';

        return '';
    }

    getPhone(user : any, phoneCodePlus : boolean = true){
        if(!user) return '';
    
        const phone = user.phone;
        const phone_code = user.phone_code;
    
        if(!phone_code) return phone;
    
        let callingCode =phone_code.callingCode 
        if(!phoneCodePlus){
          callingCode = callingCode.replace('+','');
        }
    
        return callingCode + phone
    }
    





    
  async login(info : {credential ?: any, formLogin ?: any , snackbar ?: any , data ?: any , requestManager : any, headerService : any, dialogRef ? :any ,logged ? : any , onloading ?: (l : boolean) =>void  }) {
    if(!info.credential && !info.formLogin) return;
    if(info.formLogin && info.formLogin.invalid) return;

    if(info.onloading)info.onloading(true)
    const body = info.credential? {google: info.credential} : info.formLogin.getRawValue();

    const method : string = "auth/login";

    try{
      const response = await firstValueFrom(this.api.post({body,method}));
      const result = response;
      if(info.onloading)info.onloading(false)
      if(result){
        const data = result.data;
        const token = result.token;
        info.snackbar?.close()
       
        if(info.data){
          this.auth.login(data,token,info.data?.request ? false :true);
        }else{
          this.auth.login(data,token);
        }
        
        if(info.dialogRef){
          info.dialogRef.close({logged : true});
          this.proceedToRequest({rent: info.data?.request, requestManager: info.requestManager,snackbar : info.snackbar, headerService: info.headerService});
        }
        info.logged?.emit(true);
      }
    }catch(error){
      if(error == 'ACCOUNT_NOT_ACTIVE'){
        info.dialogRef?.close({confirm_mode: true,body: body})
      }else if(error == 'NOT_FOUND'){
        info.snackbar?.text('Email or password incorrect','error', false);
      }
      if(info.onloading)info.onloading(false)
    }

  }



  proceedToRequest(info: {rent : Rent, requestManager : RequestManager , snackbar ?: any, headerService ?: any}){
    if(info.rent.isRequestingLoading()) return;

    info.rent.requesting(true);

    const body : any = {
      arrive : info.rent.getArrive()!,
      depart : info.rent.getDepart()!,
      about : info.rent.getAbout(),
    }
    info.requestManager.apply(info.rent.getRentRoom().id,body, {
      next: (result) => {
        info.snackbar?.text(info.requestManager.MESSAGE_REQUEST_SENT,'ok',8);
        info.rent.requested(result);
        this.auth.forceUpadte();
      },
      error : (result) => {
        info?.snackbar?.close();
        if(result == 'PROFILE_NOT_COMPLETED'){
          info.rent.setNeedComplete(true);
          info.headerService?.openReigster(info.rent);
          info.snackbar?.text('To proceed with your booking, please complete all required fields. Our agents will review your information and process your reservation accordingly.','warn',false);
        }else{
            info.snackbar?.text('This date is currently unavailable for booking','warn',8);
        }
      },
      finalize: () => {
        info.rent.requesting(false);
      }
    })
  }

}
